'use client';

import { useEffect, useReducer, useRef } from 'react';

import { useSubmitServerAction } from '@/lib/mutation-actions/browser';
import { FragmentType } from '@/lib/gql';
import { OperationValidationFragment } from '@/components/OperationValidation';
import { OperationSimulationFragment } from '@/components/OperationSimulation';
import { SimulationMethod } from '@/lib/gql/graphql';
import { EIP155_SIGNING_METHODS, GenericMessage, formatParams, getMessageDetail } from '@/lib/web3-access';
import { globalLogger } from '@/lib/logger';
import { ScanTransactionInput, scanTransactionAction } from '@/features/web3-modals/actions';

const logger = globalLogger.child({ scope: 'useScanTransaction' });

/**
 * Mapping of EIP155 signing methods to simulation methods
 */
const METHOD_MAPPING: Record<string, SimulationMethod | undefined> = {
  [EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION]: SimulationMethod.EthSendTransaction,
  [EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION]: SimulationMethod.EthSignTransaction,
  [EIP155_SIGNING_METHODS.PERSONAL_SIGN]: SimulationMethod.EthSign,
  [EIP155_SIGNING_METHODS.ETH_SIGN]: SimulationMethod.EthSign,
  [EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA]: SimulationMethod.EthSignTypedData,
  [EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3]: SimulationMethod.EthSignTypedDataV3,
  [EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4]: SimulationMethod.EthSignTypedDataV4,
  [EIP155_SIGNING_METHODS.ETH_SEND_RAW_TRANSACTION]: SimulationMethod.EthSendRawTransaction,
};

/**
 * State for the scan transaction hook
 */
interface State {
  isLoading: boolean;
  error: string | null;
  validation: FragmentType<typeof OperationValidationFragment> | null;
  simulation: FragmentType<typeof OperationSimulationFragment> | null;
}

/**
 * Actions for the scan transaction hook
 */
type Action =
  | { type: 'LOADING' }
  | {
      type: 'SUCCESS';
      validation: FragmentType<typeof OperationValidationFragment> | null;
      simulation: FragmentType<typeof OperationSimulationFragment> | null;
    }
  | { type: 'ERROR'; error: string };

/**
 * Reducer for the scan transaction hook
 */
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'LOADING': {
      return { ...state, isLoading: true, error: null };
    }
    case 'SUCCESS': {
      return { isLoading: false, error: null, validation: action.validation, simulation: action.simulation };
    }
    case 'ERROR': {
      return { isLoading: false, error: action.error, validation: null, simulation: null };
    }
    default: {
      return state;
    }
  }
}

/**
 * Custom hook for scanning a transaction.
 *
 * @param {GenericMessage} message - The message containing transaction details.
 * @returns {Object} An object containing the scan result data and loading state.
 * @property {boolean} isLoading - Indicates whether the scan is in progress.
 * @property {string | null} error - Error message if an error occurred, null otherwise.
 * @property {FragmentType<typeof OperationValidationFragment> | null} validation - The validation result, or null if not available.
 * @property {FragmentType<typeof OperationSimulationFragment> | null} simulation - The simulation result, or null if not available.
 */
export function useScanTransaction(message: GenericMessage) {
  const hasCalledScanTransaction = useRef(false);
  const {
    runAction: runScanTransaction,
    result: scanResult,
    errorMessage: actionErrorMessage,
  } = useSubmitServerAction({
    action: scanTransactionAction,
    defaultError: 'Error loading transaction details',
  });

  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    error: null,
    validation: null,
    simulation: null,
  });

  useEffect(() => {
    if (hasCalledScanTransaction.current) return;

    const { address: accountAddress, message: msgDetails, rawMessage: params, method } = message;
    const chainId = getMessageDetail(msgDetails, 'chainId');
    const domain = getMessageDetail(msgDetails, 'site');

    if (!accountAddress || !chainId || !params || !domain) {
      logger.error('[useScanTransaction] Missing data for scanTransaction', {
        accountAddress,
        chainId,
        params,
        domain,
      });
      dispatch({ type: 'ERROR', error: 'Missing data for scanTransaction' });
      return;
    }

    const selectedMethod = METHOD_MAPPING[method];
    if (!selectedMethod) {
      logger.error('[useScanTransaction] Unsupported method:', { method });
      dispatch({ type: 'ERROR', error: `Unsupported method: ${method}` });
      return;
    }

    let formattedParams: string;
    try {
      formattedParams = formatParams(method, params, accountAddress);
    } catch (err) {
      logger.error('[useScanTransaction] Error formatting params:', { error: err });
      dispatch({ type: 'ERROR', error: `Error formatting params: ${(err as Error).message}` });
      return;
    }

    const scanInput: ScanTransactionInput = {
      accountAddress,
      chainId,
      method: selectedMethod,
      params: formattedParams,
      domain,
    };

    dispatch({ type: 'LOADING' });
    runScanTransaction(scanInput);
    hasCalledScanTransaction.current = true;
  }, [message, runScanTransaction]);

  useEffect(() => {
    if (!scanResult) return;

    if (scanResult.data?.scanTransaction) {
      const { simulation: simulationResult, validation: validationResult } = scanResult.data.scanTransaction;

      dispatch({ type: 'SUCCESS', simulation: simulationResult ?? null, validation: validationResult ?? null });
    } else if (scanResult.error) {
      logger.error('[useScanTransaction] Error in scanTransaction:', { error: scanResult.error });
      dispatch({ type: 'ERROR', error: scanResult.error.message });
    }
  }, [scanResult]);

  useEffect(() => {
    if (!actionErrorMessage) return;
    logger.error('[useScanTransaction] Error in scanTransaction:', { error: actionErrorMessage });
    dispatch({ type: 'ERROR', error: actionErrorMessage });
  }, [actionErrorMessage]);

  return { ...state };
}
